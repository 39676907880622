import { useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'components/atoms/Link';
import Drawer from '@material-ui/core/Drawer';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';

import StoreContext from 'context/SiteContext';
import { BodyText, H2 } from 'components/atoms/Typography';
import Button from 'components/atoms/Button';
import { CartIcon, CloseCircleIcon, CloseIcon, EditPenIcon } from 'components/atoms/Icons';
import { getLineItemSampleType, roundToTwo } from 'helpers';
import SampleLineItemQuantitySelector from 'components/atoms/SampleLineItemQuantitySelector';
import { APPS } from 'helpers/constants/general';
import { logInfo } from 'helpers/logging';
import {
    getCartSubtotal,
    transformLineItemsToElevarImpressions,
    transformToElevarUserProperties,
} from 'helpers/elevar';

const SidebarCart = () => {
    const {
        store: {
            sidebarCartOpen,
            checkout,
            customer,
            customerLoggedIn,
            checkout: { currencyCode, lineItems, lineItemsSubtotalPrice },
            tradeDiscountValue,
        },
        setSidebarCartOpen,
        removeLineItem,
        setDataLayerList,
    } = useContext(StoreContext);
    const router = useRouter();
    let subtotalDiscount = 0;

    const handleEditLineItemClick = useCallback(
        async (e, item) => {
            e.preventDefault();
            setDataLayerList('Shopping cart');

            const $button = e.currentTarget;
            $button.disabled = 'true';

            router.prefetch(`/products/${item.variant.product.handle}`);

            await removeLineItem(item);

            router.push(`/products/${item.variant.product.handle}`);

            setSidebarCartOpen(false);

            $button.disabled = 'false';
        },
        [checkout]
    );

    useEffect(() => {
        if (!sidebarCartOpen) {
            return;
        }

        /** View Mini Cart **/
        window.ElevarDataLayer = window.ElevarDataLayer ?? [];
        const cartSubtotal = getCartSubtotal({
            lineItems,
            lineItemsSubtotalPrice,
            tradeDiscountValue,
        });

        const viewCartData = {
            event: 'dl_view_cart',
            user_properties: {
                ...transformToElevarUserProperties({ customer, customerLoggedIn }),
            },
            cart_total: cartSubtotal,
            ecommerce: {
                currencyCode: currencyCode,
                actionField: {
                    list: 'Shopping Cart',
                },
                impressions: transformLineItemsToElevarImpressions({
                    lineItems: lineItems?.slice(0, 30) || [],
                    tradeDiscountValue,
                }),
            },
        };
        window.ElevarDataLayer.push({ ...viewCartData });
        logInfo('Fire Elevar dl_view_cart', APPS.FRONTEND, viewCartData);
    }, [sidebarCartOpen, lineItems]);

    return (
        <Drawer
            anchor="right"
            open={sidebarCartOpen}
            onClose={() => setSidebarCartOpen(false)}
            classes={{ paper: 'w-full max-w-500px' }}
        >
            <div className="h-full flex flex-col justify-between max-h-full">
                <div className="flex flex-col items-center pt-sm">
                    <div className="w-full px-sm">
                        <div className="w-full flex justify-between items-center">
                            <H2>Cart</H2>
                            <div className="w-40px h-40px">
                                <Button
                                    type="icon"
                                    padding="0px 10px"
                                    className="h-full w-full"
                                    icon={<CloseIcon />}
                                    onClick={() => setSidebarCartOpen(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="w-full flex-1 px-sm py-m-sm overflow-scroll"
                    style={{ height: 'calc(100% - 530px' }}
                >
                    {lineItems?.length > 0 ? (
                        lineItems?.map(item => {
                            let totalDiscountedAmount = 0;
                            for (let discount of item.discountAllocations) {
                                if (
                                    discount.discountApplication.title.toUpperCase() !==
                                    'TRADE DISCOUNT'
                                ) {
                                    totalDiscountedAmount += Number(
                                        discount.allocatedAmount.amount
                                    );
                                }
                            }
                            if (
                                tradeDiscountValue &&
                                getLineItemSampleType(item) === 'non-sample'
                            ) {
                                totalDiscountedAmount +=
                                    (Number(item.variant.price.amount) *
                                        item.quantity *
                                        tradeDiscountValue) /
                                    100;
                            }

                            if (totalDiscountedAmount) {
                                subtotalDiscount += totalDiscountedAmount;
                            }

                            if (!item || !item.variant) return null;
                            return (
                                <LineItem
                                    key={item.id}
                                    item={item}
                                    handleEditLineItemClick={handleEditLineItemClick}
                                    totalDiscountedAmount={totalDiscountedAmount}
                                />
                            );
                        })
                    ) : (
                        <div className="py-xxs">
                            <BodyText>Your cart is empty.</BodyText>
                        </div>
                    )}
                </div>

                {lineItems?.length > 0 && (
                    <div className="border-t border-gray-300">
                        <div className="w-full py-m-sm px-sm">
                            <div className="flex justify-between mb-xs">
                                <BodyText>Subtotal</BodyText>
                                <BodyText>
                                    $
                                    <CurrencyFormat
                                        value={roundToTwo(
                                            lineItemsSubtotalPrice?.amount - subtotalDiscount
                                        )}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                </BodyText>
                            </div>
                            <div className="mb-xs">
                                <BodyText color="#7f7f7f">
                                    Shipping & taxes calculated at checkout
                                </BodyText>
                            </div>
                        </div>
                        <div className="w-full">
                            <Button
                                as={Link}
                                href="/cart"
                                size="large"
                                type="primary"
                                label="Checkout"
                                disabled={!lineItems.length}
                                icon={<CartIcon />}
                                onClick={() => {
                                    setSidebarCartOpen(false);
                                }}
                                className="w-full sidebar-cart-button"
                            />
                        </div>
                    </div>
                )}
            </div>
        </Drawer>
    );
};
const LineItem = ({ item, handleEditLineItemClick, totalDiscountedAmount }) => {
    const { setSidebarCartOpen, removeLineItem, setDataLayerList } = useContext(StoreContext);

    return (
        <div key={item.id} className="flex py-xxs">
            {getLineItemSampleType(item) === 'non-sample' ? (
                <Link
                    href={`/products/${item.variant.product.handle}`}
                    onClick={() => {
                        setDataLayerList('Shopping cart');
                        setSidebarCartOpen(false);
                    }}
                >
                    <div className="w-60px h-60px sm:w-20 sm:h-20">
                        {item?.variant?.image?.src && (
                            <Image
                                src={item.variant.image.src}
                                alt={
                                    item.variant.image?.altText || item?.title
                                        ? `${item.title} - featured image.`
                                        : ''
                                }
                                width={80}
                                height={80}
                            />
                        )}
                    </div>
                </Link>
            ) : (
                <div className="w-60px h-60px sm:w-20 sm:h-20">
                    {item?.variant?.image?.src && (
                        <Image
                            src={item.variant.image.src}
                            alt={
                                item.variant.image?.altText || item?.title
                                    ? `${item.title} - featured image.`
                                    : ''
                            }
                            width={80}
                            height={80}
                        />
                    )}
                </div>
            )}
            <div className="flex-1 pl-xs pr-xxs">
                <div className="flex mb-1">
                    {getLineItemSampleType(item) === 'non-sample' ? (
                        <Link
                            href={`/products/${item.variant.product.handle}`}
                            onClick={() => {
                                setDataLayerList('Shopping cart');
                                setSidebarCartOpen(false);
                            }}
                        >
                            <BodyText margin="0 5px 0 0">{item.title}</BodyText>
                        </Link>
                    ) : (
                        <BodyText margin="0 5px 0 0">{item.title}</BodyText>
                    )}
                    {item.customAttributes.find(attr => attr.key === 'Collection') ? (
                        <>
                            <BodyText
                                style={{
                                    lineHeight: '1',
                                }}
                                margin="0 5px 10px 0"
                                color="#7f7f7f"
                            >
                                {'.'}
                            </BodyText>
                            <BodyText color="#7f7f7f">
                                {
                                    item.customAttributes.find(attr => attr.key === 'Collection')
                                        .value
                                }
                            </BodyText>
                        </>
                    ) : null}
                </div>
                <BodyText as="div" color="#7f7f7f">
                    {item.customAttributes.find(attr => attr.key === 'Dimensions') ? (
                        <>
                            {item.customAttributes.find(attr => attr.key === 'Dimensions').value}
                            <br />
                        </>
                    ) : null}{' '}
                    {item.variant.title !== 'Default Title' ? (
                        <>
                            {item.variant.title} <br />
                        </>
                    ) : null}
                    Price per{' '}
                    {getLineItemSampleType(item) !== 'non-sample'
                        ? 'sample '
                        : item.customAttributes?.find(({ key }) => key === 'Pieces')
                        ? 'piece  '
                        : 'box '}
                    $
                    <CurrencyFormat
                        value={item.variant.price.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                    {item?.customAttributes.find(({ key }) => key === 'Lead Time') && (
                        <span>
                            <br />
                            {item.customAttributes.find(({ key }) => key === 'Lead Time').value}
                        </span>
                    )}
                    {item?.customAttributes?.find(attr => attr.key === 'Total ft²') ? (
                        <span>
                            <br />
                            Total ft²{' '}
                            {item.customAttributes.find(attr => attr.key === 'Total ft²').value}
                        </span>
                    ) : null}
                    {item?.customAttributes?.find(attr => attr.key === 'Total linear feet') ? (
                        <span>
                            <br />
                            Total linear feet{' '}
                            {
                                item.customAttributes.find(attr => attr.key === 'Total linear feet')
                                    .value
                            }
                        </span>
                    ) : null}
                    <br />
                    {getLineItemSampleType(item) === 'paid-sample' ||
                    item?.customAttributes.find(({ key }) => key === '__quantity_limit_per_order')
                        ?.value === '1'
                        ? 'Sample '
                        : getLineItemSampleType(item) === 'sample'
                        ? 'Samples '
                        : item.customAttributes?.find(({ key }) => key === 'Pieces')
                        ? 'Pieces '
                        : 'Boxes '}
                    {getLineItemSampleType(item) === 'paid-sample' ||
                    item?.customAttributes.find(({ key }) => key === '__quantity_limit_per_order')
                        ?.value === '1' ? (
                        item.quantity
                    ) : getLineItemSampleType(item) === 'sample' ? (
                        <div className="w-10 inline-flex ml-1">
                            <SampleLineItemQuantitySelector
                                lineItemId={item.id}
                                quantity={item.quantity}
                            />
                        </div>
                    ) : (
                        item.quantity
                    )}
                </BodyText>
                <div className="w-full flex flex-wrap mt-xs">
                    {getLineItemSampleType(item) === 'non-sample' && (
                        <button
                            type="button"
                            className="flex items-center mr-4"
                            onClick={e => handleEditLineItemClick(e, item)}
                        >
                            <BodyText color="#7f7f7f" margin="0 4px 0 0">
                                Edit
                            </BodyText>
                            <EditPenIcon fill="#7f7f7f" />
                        </button>
                    )}
                    <button
                        type="button"
                        className="flex items-center"
                        onClick={async () => await removeLineItem(item)}
                    >
                        <BodyText color="#7f7f7f" margin="0 4px 0 0">
                            Remove
                        </BodyText>
                        <CloseCircleIcon fill="#7f7f7f" />
                    </button>
                    <div className="flex-1 self-end flex justify-end text-right">
                        {item.variant?.compareAtPrice ? (
                            <BodyText color="#7f7f7f" className="mr-xxs">
                                <strike>
                                    $
                                    <CurrencyFormat
                                        value={roundToTwo(
                                            Number(item.variant.compareAtPrice.amount) *
                                                item.quantity
                                        )}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                </strike>
                            </BodyText>
                        ) : (
                            totalDiscountedAmount > 0 && (
                                <BodyText color="#7f7f7f" className="mr-xxs">
                                    <strike>
                                        $
                                        <CurrencyFormat
                                            value={roundToTwo(
                                                Number(item.variant.price.amount) * item.quantity
                                            )}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                        />
                                    </strike>
                                </BodyText>
                            )
                        )}
                        <BodyText>
                            $
                            <CurrencyFormat
                                value={roundToTwo(
                                    Number(item.variant.price.amount) * item.quantity -
                                        totalDiscountedAmount
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                            />
                        </BodyText>
                    </div>
                </div>
            </div>
        </div>
    );
};

LineItem.propTypes = {
    item: PropTypes.object,
    handleEditLineItemClick: PropTypes.func,
    totalDiscountedAmount: PropTypes.number,
};

export default SidebarCart;
